export const displayName = {
  mobileMenu: "mobileMenu",
  header: "header",
  footer: "footer",
  secondHeader: "secondHeader"
}

export const arrNavTitleAndLink = [
  {
    title: "Головна",
    link: "/",
    toDisplay: [displayName.mobileMenu, displayName.footer]
  },
  {
    title: "Каталог",
    link: "/catalog",
    toDisplay: [displayName.mobileMenu, displayName.footer]
  },
  {
    title: "Калькулятор",
    link: "/calculate",
    toDisplay: [displayName.mobileMenu, displayName.header, displayName.footer]
  },
  {
    title: "Переваги",
    link: "/advantages",
    toDisplay: [displayName.mobileMenu, displayName.header, displayName.footer]
  },
  {
    title: "Безкоштовний замір",
    popUp: 'Поділіться своїми контактами, і ми організуємо замір у зручний для Вас час',
    toDisplay: [displayName.mobileMenu, displayName.header, displayName.footer, displayName.secondHeader]
  },
  {
    title: "Співпраця з нами",
    popUp: 'Довірте нам Ваші контакти – ми створимо ідеальні умови співпраці',
    toDisplay: [displayName.mobileMenu, displayName.header, displayName.footer, displayName.secondHeader]
  },
]

export const filteredNavTitleAndLink = (titleName) => {
  if (titleName) {
    return arrNavTitleAndLink.filter(el => el.toDisplay.includes(titleName))
  } else {
    return arrNavTitleAndLink
  }
}

export const breakpointShowSecondHeader = 1504

// import {filteredNavTitleAndLink, displayName} from '@root/nameAndLinksPages.js'

// const headerNavTitleAndLink =  filteredNavTitleAndLink(displayName.header)

// {
//   headerNavTitleAndLink && headerNavTitleAndLink.map(el => {
//     return (
//       <li key={el.link} className={`${styles.navList}`}>
//         <Link to={el.link}>
//           <span>{el.title}</span>
//         </Link>
//       </li>
//     )
//   })
// }