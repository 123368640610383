import { useEffect, useState } from "react";
import { collection, getDocs } from 'firebase/firestore/lite'; 
// import { ref, getDownloadURL } from "firebase/storage";

const { db } = require(`@root/firebaseConfig.js`);

const useFetchGeneralPrices = () => {
  const [generalPrices, setGeneralPrices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      const timeout = setTimeout(() => {
        setLoading(false);
        setError('Час очікування відповіді закінчився. Будь ласка, спробуйте ще раз.');
        console.error('Час очікування відповіді закінчився. Будь ласка, спробуйте ще раз.');
      }, 10000); 

      try {
        const querySnapshot = await getDocs(collection(db, 'prices'));
        clearTimeout(timeout);

        const productsData = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const data = doc.data();

            if (doc) {
              return {
                id: doc.id,
                glass_color: data.glass_color,
                glass_processing: data.glass_processing,
                glass_protection: data.glass_protection,
              };
            }
          })
        );

        setGeneralPrices(productsData.filter(product => product));
      } catch (fetchError) {
        clearTimeout(timeout);
        console.error('Error fetching data:', fetchError);
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  return { generalPrices, loading, error };
};

export default useFetchGeneralPrices;
