import { initializeApp } from "firebase/app";
import { getStorage } from 'firebase/storage';
import { getFirestore } from "firebase/firestore/lite"; //! LITE !
// import { getFirestore } from "firebase/firestore"; 
// import { getAnalytics } from "firebase/analytics";

const { firebaseConfig } = require('@root/static-data');
// const { initializeApp } = require('firebase/app');
// const { getStorage } = require('firebase/storage');
// const { getFirestore } = require('firebase/firestore/lite');

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
// const analytics = getAnalytics(app);

export { app, db, storage };

// module.exports = { app, db, storage };
