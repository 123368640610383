import React, { createContext, useContext, useEffect } from 'react';
import useFetchProducts from '@/hooks/useFetchProducts';

const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const { products, loading, error } = useFetchProducts();
  
  return (
    <ProductContext.Provider value={{ products, loading, error }}>
      {children}
    </ProductContext.Provider>
  );
};

export const useProductContext = () => {
  return useContext(ProductContext);
};

