import { useEffect, useState } from "react";
import { collection, getDocs } from 'firebase/firestore/lite'; 
import { ref, getDownloadURL } from "firebase/storage";

const { db, storage } = require(`@root/firebaseConfig.js`);

const useFetchContext = () => {
  const [context, setContext] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      const timeout = setTimeout(() => {
        setLoading(false);
        setError('Час очікування відповіді закінчився. Будь ласка, спробуйте ще раз.');
        console.error('Час очікування відповіді закінчився. Будь ласка, спробуйте ще раз.');
      }, 10000); 

      try {
        const querySnapshot = await getDocs(collection(db, 'context'));
        clearTimeout(timeout);

        const contextData = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const data = doc.data();

            // let advantagesPdfUrl = null;
            let advantagesImages = [];

            try {
              // if (data.advantages_pdf) {
              //   advantagesPdfUrl = await getDownloadURL(ref(storage, data.advantages_pdf));
              // }

              if (data.advantages_images) {
                if (Array.isArray(data.advantages_images)) {
                  advantagesImages = await Promise.all(
                    data.advantages_images.map(async (image) => await getDownloadURL(ref(storage, image)))
                  );
                } 
                // else {
                //   advantagesImages = [await getDownloadURL(ref(storage, data.advantages_images))];
                // }
              }

            } catch (error) {
              console.error('Error fetching URLs:', error);
              setError('Error fetching URLs');
            }

            if (data) {
              return {
                id: doc.id,
                // advantages_pdf: advantagesPdfUrl,
                advantages_images: advantagesImages
              };
            }
          })
        );

        setContext(contextData.filter(el => el));
      } catch (fetchError) {
        clearTimeout(timeout);
        console.error('Error fetching data:', fetchError);
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  return { context, loading, error };
};

export default useFetchContext;
