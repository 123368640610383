exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-advantages-jsx": () => import("./../../../src/pages/advantages.jsx" /* webpackChunkName: "component---src-pages-advantages-jsx" */),
  "component---src-pages-calculate-jsx": () => import("./../../../src/pages/calculate.jsx" /* webpackChunkName: "component---src-pages-calculate-jsx" */),
  "component---src-pages-catalog-jsx": () => import("./../../../src/pages/catalog.jsx" /* webpackChunkName: "component---src-pages-catalog-jsx" */),
  "component---src-pages-confirm-purchase-jsx": () => import("./../../../src/pages/confirm-purchase.jsx" /* webpackChunkName: "component---src-pages-confirm-purchase-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-order-seccess-jsx": () => import("./../../../src/pages/order-seccess.jsx" /* webpackChunkName: "component---src-pages-order-seccess-jsx" */),
  "component---src-templates-catalog-template-jsx": () => import("./../../../src/templates/CatalogTemplate.jsx" /* webpackChunkName: "component---src-templates-catalog-template-jsx" */),
  "component---src-templates-product-template-product-template-jsx": () => import("./../../../src/templates/ProductTemplate/ProductTemplate.jsx" /* webpackChunkName: "component---src-templates-product-template-product-template-jsx" */)
}

