// static-data.js
const categoryPages = [
  {
    title: 'Душові кабіни',
    page: 'shower-cabins'
  },
  {
    title: 'Душові двері',
    page: 'shower-doors'
  },
  {
    title: 'Перегородки',
    page: 'shower-partitions'
  },
  {
    title: 'Шторки',
    page: 'shower-curtains'
  }
];
const allFilters = [
  {
    title: 'Тип форми',
    id: 'shape_type',
    shape_type: [
      {
        title: 'Квадратні',
        id: 'square',
      },
      {
        title: 'Прямокутні',
        id: 'rectangular',
      },
      {
        title: 'Пʼятикутні',
        id: 'pentagonal',
      },
      {
        title: 'Вхід з кута кабіни',
        id: 'corner_entry',
      }
    ]
  }, {
    title: 'Тип відкривання',
    id: 'opening_type',
    opening_type: [{
      title: 'Відкривного типу',
      id: 'opening',
    }, {
      title: 'Розсувного типу',
      id: 'sliding',
    }, {
      title: 'Відкривні в дві сторони',
      id: 'double_opening',
    }, {
      title: 'Розсувні в дві сторони',
      id: 'double_sliding',
    }]
  }
];


const filtersAvailable = ['shower-cabins', 'shower-doors']

const firebaseConfig = {
  apiKey: "AIzaSyCXMfPiMoov4suA5j7QFb0OetNM3gWcXos",
  authDomain: "kab-weston.firebaseapp.com",
  projectId: "kab-weston",
  storageBucket: "kab-weston.appspot.com",
  messagingSenderId: "1083608029500",
  appId: "1:1083608029500:web:8759d98dd985c67558e639",
  measurementId: "G-3CVR2D9V06"
};

const pricesConfigTitle = [
  {
    height: {
      title: 'Висота',
    }
  },
  {
    shower_cabins_size: {
      title: 'Ширина (бічна x вхідна)',
    }
  },
  {
    shower_partitions_size: {
      title: 'Ширина бокової перегородки',
      nestedSizesPricesPerMeter: true,
      nested_sets: [
        "sizes_partitions_group",
        "doorway_size_group",
        "side_panel_group"
      ]
    }
  },
  //*================================================================================================================================ 

  {
    sizes_partitions_group: {
      title: 'Ширина бокової перегородки',
      priceAndDynamicSize: true
    }
  },
  {
    doorway_size_group: {
      title: 'Розміри входу',
      priceAndDynamicSize: true
    }
  },
  {
    side_panel_group: {
      title: 'Секція поруч зі входом',
      priceAndDynamicSize: true
    }
  },
  //*================================================================================================================================ 

  {
    doorway_size: {
      title: 'Ширина входу',
    }
  },
  {
    side_panel: {
      title: 'Розміри секцій поруч із входом',
    }
  },
  {
    shower_doors_size: {
      title: 'Ширина виробу',
    }
  },
  {
    shower_curtains_size: {
      title: 'Ширина шторки',
    }
  },
  {
    door: {
      title: 'Ширина дверей',
    }
  },

  {
    fixed_panel: {
      title: 'Ширина нерухомої частини',
    }
  },
  // {
  //   pivot_element: {
  //     title: 'Ширина поворотного елемента',
  //   }
  // },
  {
    glass: {
      title: 'Обробка скла',
      configs: {
        without_matting: 'Без матування',
        solid_matting: 'Суцільне',
        intimate_zone: 'Інтим-зона',
        artistic_matting: 'Художнє'
      }
    }
  },
  {
    shower_cabins_protective: {
      title: 'Захисне покриття',
      configs: {
        no_protective: 'Без покриття',
        internal: 'Внутрішнє',
        external: 'Зовнішнє',
        internal_external: 'З обох боків'
      }
    }
  },
  {
    shower_cabins_color_furniture: {
      title: 'Колір фурнітури',
      type: ["calc", "static"],
      configs: {
        polished: 'Полірована',
        black: 'Чорна'
      }

    }
  },
  {
    shower_cabins_handle: {
      title: 'Ручка',
      type: ["calc", "static"],
      configs: {
        knob: 'Кноб',
        towel_holder: 'Ручка для рушника',
        knob_vertical: 'Рейлінг-кноб вертикальний',
        knob_horizontal: 'Рейлінг-кноб горизонтальний',
        vertical_dual_rail: 'Рейлінг двост. вертикальний',
        horizontal_dual_rail: 'Рейлінг двост. горизонтальний',
        round: 'Кругла',
      }
    }
  },
  {
    towel_holders: {
      title: 'Тримач для рушника',
      type: ["calc", "static"],
      configs: {
        none: 'Немає',
        towel_holder_w400: 'Тримач шириною 400 мм.',
      }
    }
  },

  {
    glass_holder: {
      title: 'Тримач для скла',
      type: ["calc", "static"],
      configs: {
        none: 'Немає',
        rectangular_90: 'Прямокутний 90',
        round_90: 'Круглий 90',
        round_45: 'Круглий 45',
        round_contour: 'По контуру круглий',
        rectangular_contour: 'По контуру прямокутний',
        elongated_above_door: 'Поздовжній над дверкою',
        additional_holder_above: 'Додатковий тримач скла зверху',
        elongated_rectangular: 'Прямокутний поздовжній',
        elongated_round: 'Круглий поздовжній',
      }

    }
  },

  {
    contourFrame: {
      title: 'Окантування по контуру',
      type: ["calc", "static"],
      configs: {
        withoutEdging: 'Без окантування',
        edging: 'З окантуванням',
      }
    }
  },
  {
    top_corner: {
      title: 'Верхній кут',
      type: ["calc", "static"],
      configs: {
        sharp: 'Гострий',
        rounded: 'Заокруглений',
      }
    }
  },
  {
    shower_cabins_hinge_placement: {
      title: 'Розміщення петель',
      type: ["calc", "static"],
      configs: {
        right: 'Справа',
        left: 'Зліва'
      }
    }
  },
  {
    opened_door_side: {
      title: 'Сторона відчинених дверей',
      type: ["calc", "static"],
      configs: {
        right: 'Справа',
        left: 'Зліва'
      }
    }
  },
  {
    mounting_side: {
      title: 'Сторона монтажу',
      type: ["calc", "static"],
      configs: {
        right: 'Справа',
        left: 'Зліва'
      }
    }
  },
  {
    door_seal: {
      title: 'Ущільнювачі',
      type: ["calc", "static"],
      configs: {
        ultratransparent_premuim: 'Ультрапрозорі (Premium)',
        black_premuim: 'Чорні (Premium)'
      }
    }
  },
  {
    doorSealType: {
      title: 'Тип ущільнювача дверей',
      type: ["calc", "static"],
      configs: {
        nonMagneticDoorSealType: 'Без магнітної фіксації',
        magneticDoorSealType: 'З магнітною фіксацією'
      }
    }
  }
]

const titleCalcConfigs = [
  {
    height: "Висота"
  },
  {
    width: "Ширина"
  },
  {
    side_panel: "Ширина бокової частини"
  },
  {
    door: "Ширина дверей"
  },
  {
    side_walls: "Бокові частини"
  },
  {
    left_addition: "Лівий добір"
  },
  {
    left_door: "Ліві двері"
  },
  {
    left_size_from_door_45: "Лівий розмір"
  },
  {
    entry_group: "Вхідна група"
  },
  {
    entrance_section: "Вхідна частина"
  },
  {
    right_door: "Праві двері"
  },
  {
    right_addition: "Правий добір"
  },

  {
    right_size_from_door_45: "Правий розмір"
  },

  {
    left_size_from_door_45_calculated: "Ліва стінка"
  },
  {
    right_size_from_door_45_calculated: "Права стінка"
  },



]

const titleCalcDropDownConfigs = [
  {
    glass_color: {
      title: "Колір скла",
      type: ["calc", "sizeDependent"],
      configs: {
        transparent: "Прозоре",
        graphite: "Графіт",
        bronze: "Бронза",
        extra_clear: "Екстра-клір",
      }
    },
  },
  {
    glass_processing: {
      title: "Обробка скла",
      type: ["calc", "sizeDependent"],
      configs: {
        without_matting: "Без матування",
        solid_matting: "Суцільне матування",
        intimate_zone: "Інтим-зона",
        artistic_matting: "Художнє матування",
      }
    },
  },
  {
    glass_protection: {
      title: "Захисне покриття",
      type: ["calc", "sizeDependent"],
      configs: {
        no_protective: "Без покриття",
        internal: "Внутрішнє",
        external: "Зовнішнє",
        internal_external: "З обох боків",
      }
    },
  },
]

const allPricesConfigTitle = [
  ...titleCalcConfigs,
  ...pricesConfigTitle,
  ...titleCalcDropDownConfigs,
]

const configsTabsName = [
  {
    title: "Комплектація",
    key: "mainTab"
  },
  {
    title: "Калькулятор",
    key: "calcTab"
  },
]

function configsTabsReturnName(key) {
  const result = configsTabsName.filter(el => el.key === key)[0]?.title
  return result ? result : false
}

const tabConfigsNames = {
  mainTab: configsTabsReturnName("mainTab"),
  calcTab: configsTabsReturnName("calcTab"),
}


module.exports = {
  firebaseConfig,
  categoryPages,
  allFilters,
  filtersAvailable,
  pricesConfigTitle,
  titleCalcConfigs,
  titleCalcDropDownConfigs,
  allPricesConfigTitle,
  configsTabsName,
  tabConfigsNames
};
