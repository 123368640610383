import React, { createContext, useContext, useState, useRef, useEffect } from "react";
import { breakpointShowSecondHeader } from '@root/nameAndLinksPages.js'

const SecondHeaderContext = createContext();

export const SecondHeaderProvider = ({ children }) => {
  const [showSecondHeader, setShowSecondHeader] = useState(false);
  const lastScrollY = useRef(0);

  const handleScroll = () => {
    if (window.innerWidth > breakpointShowSecondHeader) { //* 1504
      setShowSecondHeader(false);
      return
    }
    const currentScrollY = window.scrollY;

    if (currentScrollY < 10) {
      setShowSecondHeader(true);
    } else if (currentScrollY > lastScrollY.current && showSecondHeader) {
      setShowSecondHeader(false);
    } else if (currentScrollY < lastScrollY.current && !showSecondHeader) {
      setShowSecondHeader(true);
    }

    lastScrollY.current = currentScrollY;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showSecondHeader]);

  return (
    <SecondHeaderContext.Provider value={{ showSecondHeader }}>
      {children}
    </SecondHeaderContext.Provider>
  );
};

// Хук для доступу до контексту
export const useSecondHeader = () => useContext(SecondHeaderContext);
