import React, { createContext, useContext } from 'react';
import useFetchGeneralPrices from '@/hooks/useFetchGeneralPrices';

const ContextGeneralPrices = createContext();

export const GeneralPricesProvider = ({ children }) => {
  const { generalPrices, loading, error } = useFetchGeneralPrices();
  
  return (
    <ContextGeneralPrices.Provider value={{ generalPrices, loading, error }}>
      {children}
    </ContextGeneralPrices.Provider>
  );
};

export const useGeneralPrices = () => {
  return useContext(ContextGeneralPrices);
};
