import React, { createContext, useContext } from 'react';
import useFetchContext from '@/hooks/useFetchContext';

const ContextData = createContext();

export const ContextProvider = ({ children }) => {
  const { context, loading, error } = useFetchContext();
  
  return (
    <ContextData.Provider value={{ context, loading, error }}>
      {children}
    </ContextData.Provider>
  );
};

export const useContextData = () => {
  return useContext(ContextData);
};
